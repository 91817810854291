/*!
 * Module definition.
 *
 * Define the global site object/namespace and the module creation method.
 */
(function(win, $) {
  'use strict';

  // All modules are added to the global site object
  win.KGI = win.KGI || {};

  /**
   * Define a site script module.
   *
   * Defines a module with a name that will be used as the key for the module
   * on the global site object. A module in this case is just a namespaced
   * group of code without any particular conventions or limitations. A module
   * is passed some parameters, in order:
   *
   * - `core`: The global site object that has all the defined modules.
   * - `fn`: This module's namespace. Add public methods to this object.
   * - `win`: The window object.
   * - `$`: The jQuery object.
   *
   * @param {string} moduleName Name of the module.
   * @param {function} moduleFn The function containing all the module code.
   * @return {*} Whatever the module possibly returns.
   */
  win.KGI.defineModule = function(moduleName, moduleFn) {
    var core = win.KGI;

    core[moduleName] = {};

    return moduleFn(core, core[moduleName], win, $);
  };
})(window, jQuery);
