/*!
 * Site script: focus style toggling.
 *
 * Designer hates focus outlines, so add a body class name when the mouse is
 * clicked to enable hiding them. Whenever a relevant keyboard event is
 * detected, the hide class is removed and a show class is added that can be
 * used to add extra focus visibility.
 *
 * No class is set until an action is taken, so sane defaults should be used.
 */
window.KGI.defineModule('focus', function(core, fn, win, $) {
  'use strict';

  var $body = $(document.body),
    hideClass = 'hide-focus',
    showClass = 'show-focus',
    activeStatus = 'default',
    throttledOnPointer;

  /**
   * Add the class for hiding focus.
   */
  function hideFocus() {
    $body.addClass(hideClass).removeClass(showClass);
    activeStatus = 'hide';
  }

  /**
   * Add the class for showing focus.
   */
  function showFocus() {
    $body.addClass(showClass).removeClass(hideClass);
    activeStatus = 'show';
  }

  /**
   * Keydown event callback, show focus.
   *
   * @param {object} e Keydown event.
   */
  function onKeyboard(e) {
    if ('show' === activeStatus) {
      return;
    }

    // Check for Tab, Enter, Escape and Space; common control keys
    if (9 === e.which || 13 === e.which || 27 === e.which || 32 === e.which) {
      showFocus();
    }
  }

  /**
   * Pointer start callback (mouse, pointer, touch...), hide focus.
   */
  function onPointer() {
    if ('hide' === activeStatus) {
      return;
    }

    hideFocus();
  }

  /**
   * Bind the events.
   *
   * Trying to use as few as possible while still including touch. Some desktop
   * browsers expose touch events even if they're unusable in practice, so both
   * touch and mouse must be bound simultaneously.
   *
   * Some screen readers and assistive technology for touchscreens fire mouse
   * events when activating elements, so it's not perfect.
   * In the screen reader case, hiding the outline hopefully shouldn't matter
   * too much, unless a sighted user uses it to read but not necessarily to
   * navigate. The outline will be visible again on the next Tab press in that
   * case.
   * The touchscreen case should, again 'hopefully', not require an outline if
   * actual tapping occurs.
   *
   * http://patrickhlauke.github.io/touch/tests/results/
   */
  function bindEvents() {
    if (window.PointerEvent) {
      $body.on('pointerdown', onPointer);
    } else {
      // Touch screens fire mouse events for compatibility, so let's use a
      // throttled event handler to prevent double the work on every interaction
      $body.on('touchstart', throttledOnPointer);
      $body.on('mousedown', throttledOnPointer);
    }

    $body.on('keydown', onKeyboard);
  }

  /**
   * Initialization.
   */
  fn.init = function() {
    throttledOnPointer = core.util.throttle(onPointer, 50);
    bindEvents();
  };
});
