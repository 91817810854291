/*!
 * Site script: Loads posts via ajax.
 *
 * Starts by identifying the right REST endpoint, based on type of posts
 * it should fetch. Fetches event, or news(regular) posts. Calls the REST
 * endpoint, on success the posts are recieved and organized into the appropriate
 * template, finally being outputted to the post list.
 */
window.KGI.defineModule('loadPosts', function (core, fn, win, $) {
  'use strict';

  var elem = {
    $grid: $('#post-block-old-posts'),
    $loadBtn: $('#btn-fetch-more'),
    $loader: $('#btn-loader'),
  };

  var conf = {
    apiEndpoint: 'kgi/v1/posts',

    newsEndpoint: 'kgi/v1/news',

    loaderName: 'posts-loading',

    renderingClass: 'posts-inserting',

    spinnerClass: 'spinner-sections',

    doneClass: 'posts-done',

    transitionSpeed: 200,
  };

  var $btnLoadMore = $('#btn-load-old');
  var apiUrl = null;
  var ids = [];

  var blockTemplate = $('#post-block-template').html();
  var blockTemplateNoImg = $('#post-block-template-no-image').html();

  /**
   * Insert a loading text before the Ajax request is done.
   */
  function ajaxBefore() {
    var html = '';

    html += '<p class="' + conf.loaderName + '" id="' + conf.loaderName + '">';
    html += '<span class="' + conf.spinnerClass + '"></span>';
    html += '</p>';

    $btnLoadMore.hide();
    elem.$loader.append(html);
  }

  /**
   * Set the right REST endpoint, set the global variable.
   */
  function setApiURL() {
    if ($(elem.$grid).hasClass('is-event')) {
      apiUrl = core.util.getRESTEndpoint(conf.apiEndpoint);
    } else {
      apiUrl = core.util.getRESTEndpoint(conf.newsEndpoint);
      elem.$grid = $('#post-block-news');
    }
  }

  /**
   * Gets the posts associated to the current page via Ajax.
   *
   * @return {object} post data.
   */
  function getPosts() {
    var data = {
      ids: ids,
    };
    var categoryId = parseInt($btnLoadMore.data('category-id'), 10);
    if (categoryId) {
      data.categoryId = categoryId;
    }
    var tagId = parseInt($btnLoadMore.data('tag-id'), 10);
    if (tagId) {
      data.tagId = tagId;
    }

    return $.ajax({
      url: apiUrl,
      type: 'GET',
      beforeSend: ajaxBefore,
      data: data,
      dataType: 'json',
    });
  }

  /**
   * Add a class to the container, indicating that the images are being
   * inserted.
   */
  function setRenderingState() {
    $btnLoadMore.hide();
  }

  /**
   * Remove the 'inserting class' and add a done class to the container.
   */
  function setDoneState() {
    $btnLoadMore.show();

    elem.$loader.html('');
  }

  /**
   * Insert the posts into the template on the site. Adding each element
   * based on the post type, since the two types do not carry the same elements.
   *
   * @param {object} data post data.
   */
  function insertPosts(data) {
    if (data.posts) {
      var output = data.posts.reduce(function (partialOutput, block) {
        ids.push(block.id);
        var elems = {
          id: block.id,
          url: block.url,
          title: block.title,
          hasImg: block.hasImg,
        };

        var template = blockTemplateNoImg;
        if (block.img) {
          elems.img = block.img;
          template = blockTemplate;
        }
        if (block.type === 'event') {
          elems.location = block.location;
          elems.dateText = block.dateText;
        } else if (block.type === 'news') {
          elems.excerpt = block.excerpt;
          elems.categories = block.categories;
          elems.date = block.date;
          elems.readingTime = block.readingTime;
        }

        return partialOutput + core.util.template(template, elems);
      }, '');
      elem.$grid.append(output);
    }
  }

  /**
   * Hides the loading button
   */
  function hideLoadButton() {
    elem.$loadBtn.remove();
  }

  /**
   * Run functions for various tasks on Ajax success.
   *
   * @param {object} data post data.
   */
  function ajaxSuccess(data) {
    setRenderingState();

    setTimeout(function () {
      if (data.end === true) {
        hideLoadButton();
      }

      insertPosts(data);
    }, conf.transitionSpeed);

    setTimeout(function () {
      setDoneState();
    }, conf.transitionSpeed * 2);
  }

  /**
   * Logs the error to console on Ajax error.
   *
   * @param {object} data error data.
   */
  function ajaxError(data) {
    console.log(data);
  }

  /**
   * Runs the function to get posts on button toggle.
   *
   * @param {Event} e click event.
   */
  function onLoadMoreClick(e) {
    e.preventDefault();

    var request;

    // Get posts
    request = getPosts();
    request.done(ajaxSuccess).fail(ajaxError);
  }

  /**
   * Get the ids of the posts that are loaded.
   */
  function getIds() {
    var blocks = $(elem.$grid).find('.post-block > .post-block-inner');
    $.each(blocks, function () {
      ids.push($(this).data('id'));
    });
  }

  /**
   * Bind the events.
   */
  function bindEvents() {
    $btnLoadMore.on('click', onLoadMoreClick);
  }

  /**
   * Initialization.
   */
  fn.init = function () {
    // Bind the click to the load more button
    bindEvents();

    // Register the endpoint
    setApiURL();
    // Get existing ids
    getIds();
  };
});
