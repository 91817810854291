/*!
 * Site script: Flickity slider handling.
 *
 * Management and some accessibility improvements for Flickity sliders.
 */
window.KGI.defineModule('slider', function(core, fn, win, $) {
  'use strict';

  var sliderSelector = '.slider--active';
  var flickityOptions = {
    cellSelector: '.slide',
    cellAlign: 'left',
    contain: true,
    selectedAttraction: 0.1,
    friction: 0.6,
    wrapAround: true,
    pageDots: true,
    arrowShape: {
      x0: 25,
      x1: 65,
      y1: 40,
      x2: 70,
      y2: 35,
      x3: 35,
    },
  };
  var instances = [];

  /**
   * Slider instance constructor.
   *
   * @param {object} $elem jQuery object of the slider element.
   * @return {Slider} this
   */
  var Slider = function($elem) {
    var _this = this;

    this.$elem = $elem;

    this.$slides = this.$elem.find(flickityOptions.cellSelector);
    this.isLoaded = false;
    this.instance = null;
    this.currentIndex = 0;
    this.latestIndex = 0;
    this.slideTabbables = [];
    this.$slides.each(function() {
      _this.slideTabbables.push($(this).find('a,button,input'));
    });

    return this;
  };

  /**
   * Enable tabbing elements for a slide.
   *
   * @param {number} slideIndex Index of the slide to enable tabbing for.
   * @return {Slider} this
   */
  Slider.prototype._enableTabbing = function(slideIndex) {
    var $elems = this.slideTabbables[slideIndex];

    if ($elems && $elems.length) {
      $elems.removeAttr('tabIndex');
    }

    return this;
  };

  /**
   * Disable tabbing elements for a slide.
   *
   * @param {number} slideIndex Index of the slide to disable tabbing for.
   * @return {Slider} this
   */
  Slider.prototype._disableTabbing = function(slideIndex) {
    var $elems = this.slideTabbables[slideIndex];

    if ($elems && $elems.length) {
      $elems.attr('tabIndex', '-1');
    }

    return this;
  };

  /**
   * Get the index of the previously viewed slide.
   *
   * @return {number}
   */
  Slider.prototype.getPrevSlideIndex = function() {
    var cells = this.instance.cells,
      lastIndex = cells.length - 1,
      prev =
        this.latestIndex > this.currentIndex
          ? this.currentIndex + 1
          : this.currentIndex - 1;

    if (0 === this.latestIndex && this.currentIndex === lastIndex) {
      prev = 0;
    } else if (0 === this.currentIndex && this.latestIndex === lastIndex) {
      prev = lastIndex;
    }

    return prev;
  };

  /**
   * Slide event callback.
   *
   * @param {object} e Custom slide event called on the slider container.
   */
  Slider.prototype._onSlide = function() {
    var slideIndex = this.instance.selectedIndex;

    this.currentIndex = slideIndex;

    // Tabbing to things outside the viewport will both be bad for accessibility
    // and may break the slider.
    this._enableTabbing(slideIndex)._disableTabbing(this.getPrevSlideIndex());

    this.latestIndex = slideIndex;
  };

  /**
   * Run a flickity method.
   *
   * @see http://flickity.metafizzy.co/api.html
   * @param {string} method The method to run.
   */
  Slider.prototype.flickityMethod = function(method) {
    this.$elem.flickity(method);
  };

  // Set in init since core.util may not be available here
  Slider.prototype.debouncedFlickityMethod = function() {};

  /**
   * Initialize Flickity.
   */
  Slider.prototype.init = function() {
    if (this.isLoaded) {
      return;
    }

    this.debouncedFlickityMethod = core.util.debounce(this.flickityMethod, 100);

    var sliderElem = this.$elem.flickity(flickityOptions),
      i = 1,
      len = this.$slides.length - 1;

    sliderElem.on('cellSelect', this._onSlide.bind(this));

    for (i; i <= len; i++) {
      this._disableTabbing(i);
    }

    this.instance = sliderElem.data('flickity');
    this.isLoaded = true;
  };

  /**
   * Lazy loaded images callback.
   *
   * Is called each time a lazy loaded image has finished loading. If the
   * number of loaded images matches the number of slides, the slider is ready
   * to be initialized.
   *
   * See lazy-image.js.
   */
  function onImageLoad() {
    var $loadedImages;
    var loadedBackgrounds;

    instances.forEach(function(slider) {
      if (slider.isLoaded) {
        // Flickity seems to handle the resize before possible lazy image
        // resizing is done, so run a refresh manually
        slider.debouncedFlickityMethod('resize');
      } else {
        $loadedImages = slider.$elem.find(
          '.' + core.lazyImage.getLoadedClassName()
        );

        loadedBackgrounds = core.lazyImage.getBackgroundCount();

        if (
          $loadedImages.length === slider.$slides.length ||
          loadedBackgrounds === slider.$slides.length
        ) {
          slider.init();
        }
      }
    });
  }

  /**
   * Bind necessary events.
   */
  function bindEvents() {
    core.lazyImage.addLoadedCallback(onImageLoad);
  }

  /**
   * Initialization.
   */
  fn.init = function() {
    if (!$.fn.flickity) {
      return;
    }

    $(sliderSelector).each(function() {
      instances.push(new Slider($(this)));
    });

    bindEvents();
  };
});
