/*!
 * Site script: social media box.
 *
 * Runs checks on the social media share box for events and news.
 * Adds appropriate classes to the SM box based on location,
 * hiding it, showing it, as well as changing the position attribute.
 *
 * Checks for the height of the artice, fading the box out when the
 * end is reached. Checks if the SM box should be fixed in position
 * when scrolling or not. Bound to scroll event.
 */
window.KGI.defineModule('socialMediaBox', function(core, fn, win, $) {
  'use strict';

  var $win = $(win);

  var elems = {
    $fixedHeader: $('.js-post-fixed-header'),
    $socialMediaBox: $('.js-social-media-share'),
    $postArticle: $('#article'),
  };

  var conf = {
    minWidth: 1050,
  };

  var dimensions = {
    socialBoxPosition: 0,
    socialBoxHeight: 0,
    fixedHeaderHeight: 0,
  };

  var stateFixed = false;

  /**
   * Check if the social media box should be fixed or not,
   * based on scroll position and width.
   */
  function checkSocialMediaLinks() {
    if (core.util.getWindowWidth() > conf.minWidth) {
      var articlePosition = elems.$postArticle.offset();
      var articleHeight = elems.$postArticle.outerHeight();

      var setFixedOffset =
        dimensions.socialBoxPosition - dimensions.fixedHeaderHeight;

      var stopMark = articlePosition.top + articleHeight;
      stopMark =
        stopMark - dimensions.socialBoxHeight - dimensions.fixedHeaderHeight;

      if (
        stateFixed === false &&
        core.util.getScrollTop() > setFixedOffset &&
        core.util.getScrollTop() < stopMark
      ) {
        elems.$socialMediaBox.addClass('fixed').removeClass('no-fixed');
        elems.$socialMediaBox.removeClass('sm-box-hidden');
        stateFixed = true;
      } else if (stateFixed === true && core.util.getScrollTop() >= stopMark) {
        elems.$socialMediaBox.addClass('sm-box-hidden');
        stateFixed = false;
      } else if (
        stateFixed === true &&
        core.util.getScrollTop() < setFixedOffset
      ) {
        elems.$socialMediaBox.addClass('no-fixed').removeClass('fixed');
        elems.$socialMediaBox.removeClass('sm-box-hidden');
        stateFixed = false;
      }
    } else {
      elems.$socialMediaBox.removeClass('fixed no-fixed sm-box-hidden');
      stateFixed = false;
    }
  }

  /**
   * Set dimensions properties for the header and social media box.
   */
  function setDimensions() {
    dimensions.socialBoxPosition = elems.$socialMediaBox.offset().top;
    dimensions.socialBoxHeight = elems.$socialMediaBox.outerHeight();
    dimensions.fixedHeaderHeight = elems.$fixedHeader.outerHeight();
  }

  /**
   * Initialization.
   */
  fn.init = function() {
    if (elems.$socialMediaBox.length) {
      // Set scroll and resize callbacks
      $win.on('scroll', core.util.throttle(checkSocialMediaLinks, 100));
      $win.on('resize', core.util.throttle(setDimensions, 100));

      // Set dimensions without lazy image first
      setDimensions();
      // Set the dimensions after lazy image load
      core.lazyImage.addLoadedCallback(setDimensions);
    }
  };
});
