/*!
 * Site script: toggle buttons.
 *
 * General solution for buttons that toggle class names.
 */
window.KGI.defineModule('toggle', function(core, fn, win, $) {
  'use strict';

  var buttonClass = 'js-toggle',
    buttonSelector = '.' + buttonClass,
    instances = [],
    $doc = $(document),
    $body = $(document.body);

  /**
   * Toggle button constructor.
   *
   * Toggle buttons are controlled with attributes. The open/active/enabled
   * state is assumed to be when the class name is present on the toggle target.
   *
   * Attributes:
   *
   * - class: REQUIRED. Must contain the `js-toggle` class name.
   * - data-toggle: REQUIRED. Class name to toggle on the target (as set with
   *   `data-toggle-on`).
   * - data-toggle-on: Keyword or valid CSS selector of element to toggle the
   *   class name on. Keyword can be `self` or `parent` Defaults to body if
   *   none is set.
   * - aria-controls: ID of element that is affected by the toggle state. If
   *   set, aria-expanded will be handled automatically.
   * - data-close-outside: If true, the toggle will be closed when clicking
   *   outside the target (excluding the button). Requires an ARIA target
   *   through `aria-controls`.
   * - data-open-text: Text to set for the 'to open' state.
   * - data-close-text: Text to set for the 'to close' state.
   * - data-aria-open-text: Text to set in aria-label for the 'to open' state.
   * - data-aria-close-text: Text to set in aria-label for the 'to close' state.
   *
   * Example:
   *
   *     <article>
   *       <p>Summary text</p>
   *       <button
   *         type="button"
   *         class="js-toggle"
   *         data-toggle="is-open"
   *         data-toggle-on="#full-text"
   *         aria-controls="full-text"
   *         data-open-text="Read full article"
   *         data-close-text="Close article">
   *           Read full article
   *       </button>
   *       <div id="full-text">
   *         Full article text
   *       </div>
   *     </article>
   *
   * @param {object} $btn jQuery object of the toggle button.
   * @return {Toggle} this
   */
  var Toggle = function($btn) {
    var className = $btn.data('toggle'),
      target = $btn.data('toggle-on'),
      ariaTargetId = $btn.attr('aria-controls'),
      $child,
      $ariaTarget;

    // Must at least have a class name to toggle
    if (!className) {
      if (window.console) {
        window.console.warn(
          'Toggle button missing data-toggle attribute with a class name.',
          $btn[0]
        );
      }

      return this;
    }

    if ('self' === target) {
      this.$target = $btn;
    } else if ('parent' === target) {
      this.$target = $btn.parent();
    } else if (target) {
      // Safeguard against multiple matches
      this.$target = $(target).first();
    } else {
      this.$target = $body;
    }

    // Try finding a .text element, fall back to the first child. This will
    // be used to toggle the text, if set, without removing other elements
    // like icons that may exist.
    $child = $btn.children('.text');
    if (!$child.length) {
      $child = $btn.children().first();
    }

    this.$button = $btn;
    this.$child = $child;
    this.className = className;
    this.openText = $btn.data('open-text');
    this.closeText = $btn.data('close-text');
    this.ariaOpenText = $btn.data('aria-open-text');
    this.ariaCloseText = $btn.data('aria-close-text');
    this.closeOutside = !!$btn.data('close-outside');
    this.isOpen = this.$target.hasClass(className);
    this.$ariaTarget = null;

    if (ariaTargetId) {
      $ariaTarget = $('#' + ariaTargetId);

      if ($ariaTarget.length) {
        this.$ariaTarget = $ariaTarget;
        $btn.attr('aria-expanded', this.isOpen ? 'true' : 'false');
      }
    }

    return this;
  };

  /**
   * Toggle the class name on the target.
   *
   * @return {Toggle} this
   */
  Toggle.prototype._toggleClassName = function() {
    this.$target.toggleClass(this.className);

    return this;
  };

  /**
   * Set the isOpen boolean depending on the toggle class name.
   *
   * @return {Toggle} this
   */
  Toggle.prototype._setOpenState = function() {
    this.isOpen = this.$target.hasClass(this.className);

    return this;
  };

  /**
   * Toggle aria-expanded on the button.
   *
   * @return {Toggle} this
   */
  Toggle.prototype._toggleAriaExpanded = function() {
    var attr;

    if (this.$ariaTarget) {
      attr = this.$button.attr('aria-expanded');

      if ('true' === attr) {
        attr = 'false';
      } else {
        attr = 'true';
      }

      this.$button.attr('aria-expanded', attr);
    }

    return this;
  };

  /**
   * Toggle open/close text on the button.
   *
   * @return {Toggle} this
   */
  Toggle.prototype._toggleTextContent = function() {
    var text, $el;

    if (this.openText && this.closeText) {
      text = this.isOpen ? this.closeText : this.openText;
      $el = this.$child.length ? this.$child : this.$button;

      $el.text(text);
    }

    return this;
  };

  /**
   * Toggle aria-label on the button.
   *
   * @return {Toggle} this
   */
  Toggle.prototype._toggleAriaLabel = function() {
    var text;

    if (this.ariaOpenText && this.ariaCloseText) {
      text = this.isOpen ? this.ariaCloseText : this.ariaOpenText;
      this.$button.attr('aria-label', text);
    }

    return this;
  };

  /**
   * Toggle the state.
   *
   * @return {Toggle} this
   */
  Toggle.prototype.toggle = function() {
    this._toggleClassName()
      ._setOpenState()
      ._toggleAriaExpanded()
      ._toggleTextContent()
      ._toggleAriaLabel();

    return this;
  };

  /**
   * Set the state to open/on/active/enabled etc.
   *
   * @return {Toggle} this
   */
  Toggle.prototype.open = function() {
    if (!this.isOpen) {
      this.toggle();
    }

    return this;
  };

  /**
   * Set the state to closed/off/inactive/disabled etc.
   *
   * @return {Toggle} this
   */
  Toggle.prototype.close = function() {
    if (this.isOpen) {
      this.toggle();
    }

    return this;
  };

  /**
   * Initialize all the toggle buttons. Data is saved on the node under
   * `toggle`.
   */
  function initToggles() {
    $(buttonSelector).each(function() {
      var $btn = $(this),
        toggle = new Toggle($btn);

      instances.push(toggle);
      $btn.data('toggle', toggle);
    });
  }

  /**
   * Toggle button click callback.
   *
   * @param {object} e The click event object.
   */
  function onToggleClick(e) {
    e.preventDefault();
    $(this)
      .data('toggle')
      .toggle();
  }

  /**
   * Document click callback.
   *
   * Close toggles that don't contain the current click target.
   *
   * @param {object} e The click event object.
   */
  function closeOutsideClick(e) {
    instances.forEach(function(toggle) {
      // The toggle has the necessary 'flags' and the click target is none of
      // the toggle button or the toggle target
      if (
        toggle.isOpen &&
        toggle.closeOutside &&
        toggle.$ariaTarget &&
        !toggle.$ariaTarget[0].contains(e.target) &&
        !toggle.$button[0].contains(e.target)
      ) {
        toggle.close();
      }
    });
  }

  /**
   * Bind the events.
   */
  function bindEvents() {
    $doc.on('click', buttonSelector, onToggleClick);
    $doc.on('click', closeOutsideClick);
  }

  /**
   * Get the class name used for toggle buttons.
   *
   * @return {string}
   */
  fn.getButtonClass = function() {
    return buttonClass;
  };

  /**
   * Initialization.
   */
  fn.init = function() {
    initToggles();
    bindEvents();
  };
});
