/*!
 * Site script: core.
 *
 * General/global data and module initialization.
 */
window.KGI.defineModule('core', function(core, fn, win, $) {
  'use strict';

  var doc = win.document;

  /* Custom Modernizr tests */
  var modernizrTest = {
    posFixed: 'positionfixed',
  };

  /* Smooth scroll script options */
  core.smoothScrollOptions = {
    // Selector for links (must be a valid CSS selector)
    selector: '.smooth-scroll',

    // Selector for fixed headers (must be a valid CSS selector)
    selectorHeader: '.smooth-scroll-header',

    // Integer. How fast to complete the scroll in milliseconds
    speed: 400,

    // Easing pattern to use
    easing: 'easeOutQuad',

    // Integer. How far to offset the scrolling anchor location in pixels
    offset: 0,

    // Update the URL hash on scroll
    updateURL: false,
  };

  var smallHeaderActive = false;

  var $win = $(win);

  /* DOM elements */
  var elems = {
    $html: $(doc.documentElement),
    $body: $(doc.body),
    $smallHeader: $('.js-post-fixed-header'),
  };

  core.themeData = {};

  /**
   * Add custom feature tests to Modernizr.
   */
  function addCustomModernizrTests() {
    if (win.Modernizr && win.Modernizr.addTest) {
      win.Modernizr.addTest(
        modernizrTest.posFixed,
        core.util.supportsPositionFixed
      );
    }
  }

  /**
   * Check if the header menu should be fixed or not,
   * apply right classes based on scroll position
   */
  function checkFixedMenu() {
    if (
      false === smallHeaderActive &&
      core.util.getScrollTop() > core.util.getPageHeroHeight()
    ) {
      elems.$smallHeader.addClass('fixed-header-active');
      smallHeaderActive = true;
    } else if (
      true === smallHeaderActive &&
      core.util.getScrollTop() < core.util.getPageHeroHeight()
    ) {
      elems.$smallHeader.removeClass('fixed-header-active');
      smallHeaderActive = false;
    }
  }

  /**
   * Initialize all the modules when relevant.
   */
  function initModules() {
    // Internal data
    core.util.init();

    // Image slider. Init before lazy images since it uses the load callback.
    core.slider.init();

    // Lazy loaded images
    core.lazyImage.init();

    // Misc. accessibility
    core.a11y.init();

    // Element filtering
    core.filter.init();

    // Focus style
    core.focus.init();

    // Content lightbox
    core.lightbox.init();

    // Load old posts
    core.loadPosts.init();

    // Social Media sharing box
    core.socialMediaBox.init();

    // Modules that require data from the theme
    if (typeof win.KGI_THEME_DATA !== 'undefined') {
      core.themeData = win.KGI_THEME_DATA;

      // Navigation improvements
      core.navigation.init();

      // Initialize contact form validation
      if (core.themeData.initForm) {
        // core.form.init();
      }

      // Toggle buttons. Other modules may dynamically add toggles, so init last.
      core.toggle.init();
    } else if (win.console) {
      console.log(
        'Required config object KGI_THEME_DATA not present. Check template functions in the theme.'
      );
    }
  }

  /**
   * Initialize third-party scripts.
   */
  function onImageLoaded() {
    if (win.smoothScroll) {
      win.smoothScroll.init(core.smoothScrollOptions);
    }
  }

  function bindGlobalEvents() {
    core.lazyImage.addLoadedCallback(onImageLoaded);
  }

  /**
   * Initialization.
   */
  core.init = function() {
    addCustomModernizrTests();
    initModules();
    bindGlobalEvents();

    $win.on('scroll', core.util.throttle(checkFixedMenu, 100));
  };
});

// Add close animation to Magnific Popup (Lucid Gallery Lightbox).
if (window.LGLJL_OPTIONS && window.Modernizr.csstransitions) {
  window.LGLJL_OPTIONS.removalDelay = 250;
}

// Init on document ready
jQuery(document).ready(window.KGI.init);
