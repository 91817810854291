/*!
 * Site script: variout accessibility improvements.
 *
 * Clickable:
 *
 * If possible one should use elements that are interactable by default, like
 * buttons and links. When that doesn't really fit, use the `js-clickable`
 * class on whatever will have interactions, say a div, and set an `aria-label`
 * with some relevant text. That element will then behave like a real button.
 */
window.KGI.defineModule('a11y', function(core, fn, win, $) {
  'use strict';

  var $body = $(document.body);
  var clickableSelector = '.js-clickable';
  var $clickables = null;

  /**
   * Make clickable elements tabbable and set the role to button.
   */
  function makeButtonIsh() {
    $clickables.attr({
      tabindex: '0',
      role: 'button',
    });
  }

  /**
   * Listen for enter space and presses to trigger click.
   *
   * @param {object} e - Keydown event.
   */
  function onClickableKeyboard(e) {
    if (13 === e.which || 32 === e.which) {
      e.preventDefault();
      $(this).trigger('click');
    }
  }

  /**
   * Bind the events.
   */
  function bindEvents() {
    $body.on('keydown', clickableSelector, onClickableKeyboard);
  }

  /**
   * Initialization.
   */
  fn.init = function() {
    $clickables = $(clickableSelector);

    makeButtonIsh();
    bindEvents();
  };
});
