/**
 * Fix skip link navigation.
 *
 * https://www.nczonline.net/blog/2013/01/15/fixing-skip-to-content-links/
 */
(function(win) {
  'use strict';

  var ua = navigator.userAgent.toLowerCase(),
    interactiveElement = /^(?:a|select|input|button|textarea)$/i;

  /**
   * Focus target element on window haschange event.
   */
  function onHashchange() {
    var id = win.location.hash.substring(1),
      element;

    if (!id) {
      return;
    }

    element = document.getElementById(id);

    if (element) {
      // Add tabindex to non-interactive elements
      if (!interactiveElement.test(element.tagName)) {
        element.tabIndex = -1;
      }

      element.focus();
    }
  }

  // Firefox does the right thing. Don't bother with old IE events.
  if (ua.indexOf('firefox') === -1 && win.addEventListener) {
    win.addEventListener('hashchange', onHashchange, false);
  }
})(window);
