/*!
 * Site script: Element filtering.
 *
 * General solution for filtering visibility of an element collection. Example:
 *
 *   <div [containerSelector]>
 *     <p>Filter baskets of animals</p>
 *     <div [controlsSelector]>
 *       <button data-[controlTermsDataAttr]="all">All</button>
 *       <button data-[controlTermsDataAttr]="dogs">Only with dogs</button>
 *       <button data-[controlTermsDataAttr]="cats">Only with cats</button>
 *     </div>
 *     <div [itemContainerSelector]>
 *       <div [itemSelector] data-[filterTermsDataAttr]="all">Has them all</div>
 *       <div [itemSelector] data-[filterTermsDataAttr]="cats dogs">Has cats and dogs</div>
 *       <div [itemSelector] data-[filterTermsDataAttr]="dogs">Has dogs</div>
 *       <div [itemSelector] data-[filterTermsDataAttr]="cats">Has cats</div>
 *     </div>
 *   </div>
 */
window.KGI.defineModule('filter', function(core, fn, win, $) {
  'use strict';

  // Element that wraps both the filter controls and the list
  var containerSelector = '.js-filter';

  // Element that wraps the filter buttons
  var controlsSelector = '.js-filter-controls';

  // Element that wraps all filter items
  var itemContainerSelector = '.js-filter-items-wrap';

  // Each element that should be filtered
  var itemSelector = '.js-filter-item';

  // Data attribute name on each control button that contains the term that
  // said button filters
  var controlTermsDataAttr = 'filter';

  // Data attribute name on each filter item that contains the terms for the
  // item to be active
  var filterTermsDataAttr = 'filter';

  // Separator for multiple terms in the filterTermsDataAttr value
  var filterTermsSeparator = ' ';

  // Filter value for when no particular term is selected
  var defaultFilterTerm = 'all';

  // Class that is set on the currently active filter control button
  var selectedControlClass = 'is-active';

  // Class that is set on items that don't have the current filter term
  var inactiveClass = 'hidden';

  // Time in miliseconds that the filter fade transition should last
  var fadeDuration = 200;

  // Filter object instances
  var instances = [];

  /**
   * Filter instance constructor.
   *
   * @param {object} $elem jQuery object of the filter container element.
   * @return {Filter} this
   */
  var Filter = function($elem) {
    this.$elem = $elem;
    this.$controlsWrap = this.$elem.find(controlsSelector);
    this.$controls = this.$controlsWrap.find('button,a,option');
    this.$itemsWrap = this.$elem.find(itemContainerSelector);
    this.$items = this.$elem.find(itemSelector);

    this.init();

    return this;
  };

  /**
   * Get an item's filter terms.
   *
   * @param {object} $item jQuery object of the item to get terms for.
   * @return {array}
   */
  Filter.prototype.getItemTerms = function($item) {
    return $item.data(filterTermsDataAttr).split(filterTermsSeparator);
  };

  /**
   * Check if an item is matches the current filter term.
   *
   * @param {object} $item jQuery object of the item to check.
   * @param {string} filterTerm The filter term to check.
   * @return {bool}
   */
  Filter.prototype.isItemActive = function($item, filterTerm) {
    var itemTerms = this.getItemTerms($item);

    return (
      itemTerms.indexOf(filterTerm) !== -1 ||
      itemTerms.indexOf(defaultFilterTerm) !== -1
    );
  };

  /**
   * Get all items that match the specified filter term.
   *
   * @param {string} filterTerm The term to filter against.
   * @return {object} jQuery collection.
   */
  /*Filter.prototype.getActiveItems = function (filterTerm) {
		var _this = this;

		return this.$items.filter(function () {
			return _this.isItemActive($(this), filterTerm);
		});
	};*/

  /**
   * Get all items that do NOT match the specified filter term.
   *
   * @param {string} filterTerm The term to filter against.
   * @return {object} jQuery collection.
   */
  /*Filter.prototype.getInactiveItems = function (filterTerm) {
		var _this = this;

		return this.$items.filter(function () {
			return !_this.isItemActive($(this), filterTerm);
		});
	};*/

  /**
   * Run a transition callback if supplied.
   *
   * @param {?function} cb Callback to run when the transition is done.
   */
  Filter.prototype._runTransitionCallback = function(cb) {
    if ('function' === typeof cb) {
      setTimeout(function() {
        cb();
      }, fadeDuration);
    }
  };

  /**
   * Fade out the list of items.
   *
   * @param {function} cb Callback to run when the fade is done.
   */
  Filter.prototype.fadeOutItems = function(cb) {
    if (fadeDuration) {
      this.$itemsWrap.css('opacity', 0);
    }

    this._runTransitionCallback(cb);
  };

  /**
   * Fade in the list of items.
   *
   * @param {function} cb Callback to run when the fade is done.
   */
  Filter.prototype.fadeInItems = function(cb) {
    if (fadeDuration) {
      this.$itemsWrap.css('opacity', 1);
    }

    this._runTransitionCallback(cb);
  };

  /**
   * Set which filter control button should be active.
   *
   * @param {object} $controlButton jQuery object of the control button.
   * @return {Filter} this
   */
  Filter.prototype.setActiveControl = function($controlButton) {
    // Remove active class on previous control
    this.$controls
      .filter('.' + selectedControlClass)
      .removeClass(selectedControlClass);

    // Add class on current control
    $controlButton.addClass(selectedControlClass);

    return this;
  };

  /**
   * Filter all items.
   *
   * @param {string} filterTerm The term to filter against.
   * @return {Filter} this
   */
  Filter.prototype.filterItems = function(filterTerm) {
    var _this = this;

    _this.$items.each(function() {
      var $item = $(this);

      if (
        filterTerm === defaultFilterTerm ||
        _this.isItemActive($item, filterTerm)
      ) {
        $item.removeClass(inactiveClass);
      } else {
        $item.addClass(inactiveClass);
      }
    });

    return this;
  };

  /**
   * Run filtering when a control button is clicked.
   *
   * @param {obejct} e The click event.
   */
  Filter.prototype.handleFilterClick = function(e) {
    var _this = e.data.context,
      $btn = $(this),
      filterTerm = '';

    // Check if the filter is a select list, else run normal.
    if ($(this).is('select')) {
      filterTerm = $btn.find(':selected').data(controlTermsDataAttr);
    } else {
      filterTerm = $btn.data(controlTermsDataAttr);
    }

    e.preventDefault();

    _this.setActiveControl($btn).fadeOutItems(function() {
      _this.filterItems(filterTerm).fadeInItems();
    });
  };

  /**
   * Bind filter events.
   *
   * @return {Filter} this
   */
  Filter.prototype.bindEvents = function() {
    this.$controlsWrap.on(
      'click',
      'button',
      { context: this },
      this.handleFilterClick
    );
    this.$controlsWrap.on(
      'change',
      'select',
      { context: this },
      this.handleFilterClick
    );

    return this;
  };

  /**
   * Initialize the filter.
   *
   * @return {Filter} this
   */
  Filter.prototype.init = function() {
    this.bindEvents();

    if (win.Modernizr.csstransitions) {
      // jQuery handles prefixes
      this.$itemsWrap.css('transition', 'opacity ' + fadeDuration + 'ms ease');
    } else {
      fadeDuration = 0;
    }

    this.setActiveControl(this.$controls.first());

    return this;
  };

  /**
   * Initialize all the filters. Data is saved on the node under `filter`.
   */
  function initFilters() {
    $(containerSelector).each(function() {
      var $container = $(this),
        filter = new Filter($container);

      instances.push(filter);
      $container.data('filter', filter);
    });
  }

  /**
   * Initialization.
   */
  fn.init = function() {
    initFilters();
  };
});
