/*!
 * Site script: lazy loading image sizes.
 *
 * Could probably start using something proper like Picturefill, but this is
 * a fraction of the size and will definitely work everywhere.
 *
 * Use the Tag::lazy_image method for usable output.
 */
window.KGI.defineModule('lazyImage', function(core, fn, win, $) {
  'use strict';

  var $win = $(win),
    $doc = $(win.document),
    imageClass = 'js-lazy-image',
    backgroundClass = 'js-lazy-background',
    wrapSelector = '.' + imageClass + ', .' + backgroundClass,
    addedImageClass = 'lazy-image-added',
    loadedImageClass = 'lazy-image-loaded',
    loadTimerDataName = 'kgiLoadedTimer',
    loadedEventName = 'kgi.imageLoaded',
    winWidth = 0,
    $elements = null,
    imageCount = 0,
    backgroundCount = 0,
    loadedCallbacks = [];

  /**
   * Get all the set sizes.
   *
   * @param {object} $el jQuery object of element to get sizes from.
   * @return {array} Size names.
   */
  function getSizes($el) {
    var sizes = $el.data('sizes');

    if (sizes) {
      sizes = sizes.split(' ');
    } else {
      sizes = [];
    }

    return sizes;
  }

  /**
   * Check if a lazy image is a background.
   *
   * @param {object} img Image DOM node.
   * @return {bool}
   */
  function isImageBackground(img) {
    return img.className.indexOf(backgroundClass) !== -1;
  }

  /**
   * Count which elements are images and which are backgrounds.
   */
  function countElements() {
    $elements.each(function() {
      if (isImageBackground(this)) {
        backgroundCount++;
      } else {
        imageCount++;
      }
    });
  }

  /**
   * Callback for when an image is loaded.
   *
   * The jQuery object of the image is bound to `this`.
   */
  function imageLoaded() {
    var $img = this;

    clearTimeout($img.data(loadTimerDataName));
    $img.addClass(loadedImageClass);
    $doc.trigger(loadedEventName, $img);
  }

  /**
   * Insert an image tag.
   *
   * @param {object} $container jQuery object of element to append the image to.
   * @param {string} url Image src URL.
   * @param {string} alt Image alt text.
   * @param {object} extraAttr Extra HTML attributes to add.
   * @param {bool} isUpdate If already loaded images are being updated.
   */
  function insertImage($container, url, alt, extraAttr, isUpdate) {
    var $img, $oldImg, loadCallback, attr;

    // Drop out early instead of inserting the same image again
    if (isUpdate) {
      $oldImg = $container.children('.' + addedImageClass);

      if ($oldImg.attr('src') === url) {
        return;
      }
    }

    $img = $('<img>');
    loadCallback = imageLoaded.bind($img);
    attr = { src: url, alt: alt, class: addedImageClass };
    attr = $.extend({}, attr, extraAttr);

    $img.on('load', loadCallback);
    $img.attr(attr);

    // Fallback if the load event doesn't fire
    $img.data(loadTimerDataName, setTimeout(loadCallback, 2500));

    if (isUpdate) {
      $oldImg.replaceWith($img);
    } else {
      $container.append($img);
    }
  }

  /**
   * Insert a background image.
   *
   * @param {object} $container jQuery object of element with the image data.
   * @param {string} url Image URL.
   * @param {bool} isUpdate If already loaded images are being updated.
   */
  function insertBackground($container, url, isUpdate) {
    var backgroundStyle = 'url(' + url + ')';

    // Drop out early instead of inserting the same image again
    if (isUpdate && $container.css('background-image') === backgroundStyle) {
      return;
    }

    $container.css('background-image', backgroundStyle);
  }

  /**
   * Check all lazy image containers and insert appropriate sizes.
   *
   * @param {bool} isUpdate If already loaded images are being updated.
   */
  function insertImages(isUpdate) {
    $elements.each(function() {
      var $el = $(this),
        isBackground = isImageBackground(this),
        defaultUrl = $el.data('default'),
        insertSize = defaultUrl,
        alt = $el.data('alt'),
        extraAttr = $el.data('extra-attr'),
        sizes = getSizes($el),
        i = 0,
        len = sizes.length,
        size,
        sizeUrl;

      if (!alt) {
        alt = '';
      }

      if (!extraAttr) {
        extraAttr = {};
      }

      for (i; i < len; i++) {
        if (!sizes[i]) {
          continue;
        }

        size = parseInt($el.data(sizes[i] + '-size'), 10);
        sizeUrl = $el.data(sizes[i]);

        if (!isNaN(size) && sizeUrl && winWidth <= size) {
          insertSize = sizeUrl;
          break;
        }
      }

      if (insertSize) {
        if (isBackground) {
          insertBackground($el, insertSize, isUpdate);
        } else {
          insertImage($el, insertSize, alt, extraAttr, isUpdate);
        }
      }
    });
  }

  /**
   * Window resize callback; update images.
   */
  function resized() {
    winWidth = $win.width();

    insertImages(true);
  }

  /**
   * Run added callbacks when images are loaded.
   */
  function handleLoadedCallback() {
    var i = 0,
      len = loadedCallbacks.length;

    if (len) {
      for (i; i < len; i++) {
        loadedCallbacks[i]();
      }
    }
  }

  /**
   * Register events for loaded images.
   *
   * Will run callbacks when lazy images are done loading, or fallback to the
   * window load event if there are no images. Doesn't count backgrounds, since
   * they probably won't affect anything of importance.
   */
  function registerLoadEvent() {
    var cb = core.util.debounce(handleLoadedCallback, 200);

    if (imageCount) {
      $doc.on(loadedEventName, cb);
    } else {
      $win.on('load', cb);
    }
  }

  /**
   * Get the name of the custom image load event that is triggered on the
   * document when an image is loaded.
   *
   * @return {string}
   */
  fn.getLoadedEventName = function() {
    return loadedEventName;
  };

  /**
   * Get the class name that is added to loaded images.
   *
   * @return {string}
   */
  fn.getLoadedClassName = function() {
    return loadedImageClass;
  };

  /**
   * Get the number of lazy images on the page.
   *
   * @return {number}
   */
  fn.getImageCount = function() {
    return imageCount;
  };

  /**
   * Get the number of lazy backgrounds on the page.
   *
   * @return {number}
   */
  fn.getBackgroundCount = function() {
    return backgroundCount;
  };

  /**
   * Add a 'lazy image loaded' callback that falls back to window load.
   *
   * @param {function} cb Callback to run.
   */
  fn.addLoadedCallback = function(cb) {
    if ('function' === typeof cb) {
      loadedCallbacks.push(cb);
    }
  };

  /**
   * Initialization.
   */
  fn.init = function() {
    $elements = $(wrapSelector);

    countElements();
    registerLoadEvent();

    if ($elements.length) {
      winWidth = $win.width();
      insertImages();
      core.util.addResizeCallback(resized);
    }
  };
});
